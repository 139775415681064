<template>
  <div class="tabbar-Line">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
export default {
  name: "bar",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    chartClass: {
      type: String,
      default: "55",
    },
    data1: {
      type: [Number, String],
      default() {
        return 0 | "0";
      },
    },
    data2: {
      type: [Number, String],
      default() {
        return 0 | "0";
      },
    },
    title: {
      type: String,
      default: "质保期剩余",
    },
    ringColor: {
      type: Array,
      default() {
        return [
          {
            offset: 0,
            color: "#00f2fe", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "#4facfe", // 100% 处的颜色
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    data1() {
      this.drawWeatherLine();
    },
    data2() {
      this.drawWeatherLine();
    },
    ringColor() {
      this.drawWeatherLine();
    },
    title() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      console.log(this.data1, this.data2);
      // 绘制图表
      let option = {
        title: {
          text: "质保期剩余",
          x: 43,
          y: "center",
          textStyle: {
            color: "#fff",
            fontSize: "12",
          },
        },
        color: ["#546ef3", "#a6cb09", "#f8bd00"],
        legend: {
          x: 150,
          // y: 120,
          icon: "circle",
          textStyle: {
            color: "#fft",
            fontSize: 12,
          },
          padding: 0,
          itemWidth: 10,
        },
        tooltip: {
          show: true,
          trigger: "item",
          // formatter: "{b} : <br/>{d}%",
          formatter: "{b} : <br/>{c}%",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
        },
        series: [
          // 双环图
          {
            type: "pie",
            radius: ["50%", "60%"],
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            center: ["40%", "50%"],
            data: [
              {
                name: "系统",
                value: this.data1,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#3DF2BE",
                      },
                      {
                        offset: 1,
                        color: "#44E781",
                      },
                    ]),
                  },
                },
              },
              {
                value: 100 - this.data1,
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#485271",
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                  emphasis: {
                    color: "#485271",
                  },
                },
              },
            ],
          },
          {
            type: "pie",
            radius: ["65%", "80%"],
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            center: ["40%", "50%"],
            data: [
              {
                name: "电池",
                value: this.data2,
                itemStyle: {
                  normal: {
                    // color: "#fb734e",

                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#00F2FE",
                      },
                      {
                        offset: 1,
                        color: "#4FACFE",
                      },
                    ]),
                  },
                },
              },
              {
                value: 100 - this.data2,
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#485271",
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                  emphasis: {
                    color: "#485271",
                  },
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabbar-Line{
  width: 100%;
  height: 100% !important;
  //position: absolute;
  //top:-20px
  }

  .ringChartClass{

  height: 200px !important;
  }
</style>
