<template>
  <div class="line-container">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/line");
export default {
  name: "BLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    isFresh: {
      type: Boolean,
      default() {
        return false;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    data1: {
      type: Array,
      default() {
        return [];
      },
    },
    data2: {
      type: Array,
      default() {
        return [];
      },
    },
    data3: {
      type: Array,
      default() {
        return [];
      },
    },
    data4: {
      type: Array,
      default() {
        return [];
      },
    },
    title1: {
      type: String,
      default() {
        return "";
      },
    },
    title2: {
      type: String,
      default() {
        return "";
      },
    },
    title3: {
      type: String,
      default() {
        return "";
      },
    },
    title4: {
      type: String,
      default() {
        return "";
      },
    },
    yName1: {
      type: String,
      default() {
        return "";
      },
    },
    yName2: {
      type: String,
      default() {
        return "";
      },
    },
    yName3: {
      type: String,
      default() {
        return "";
      },
    },
    yAxisIndex: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    isFresh() {
      this.drawWeatherLine();
    },
    xData() {
      this.drawWeatherLine();
    },
    data1() {
      this.drawWeatherLine();
    },
    data2() {
      this.drawWeatherLine();
    },
    data3() {
      this.drawWeatherLine();
    },
    data4() {
      this.drawWeatherLine();
    },
    title1() {
      this.drawWeatherLine();
    },
    title2() {
      this.drawWeatherLine();
    },
    title3() {
      this.drawWeatherLine();
    },
    title4() {
      this.drawWeatherLine();
    },
    yName1() {
      this.drawWeatherLine();
    },
    yName2() {
      this.drawWeatherLine();
    },
    yName3() {
      this.drawWeatherLine();
    },
    yAxisIndex() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // 绘制图表
      this.xData.forEach((v) => {
        if (v.soc == null || v.soc == 65535) {
          v.soc = 0;
        }
      });
      myChart.setOption(
        {
          color: ["#00D3FF", "#00D19E", "#ffc328", "#d04c79"],
          dataset: {
            dimensions: [
              "havePowerLoad",
              "havePowerNet",
              "havePowerSystem",
              "historyTime",
              "soc",
            ],
            source: this.xData,
          },
          legend: {
            data: [
              {
                name: this.title1,
                icon: "rect",
              },
              {
                name: this.title2,
                icon: "rect",
              },
              {
                name: this.title3,
                icon: "rect",
              },
              {
                name: this.title4,
                icon: "rect",
              },
            ],
            right: 10,
            top: 0,
            x: "center",
            textStyle: {
              color: "#fff",
            },
          },
          grid: {
            left: 50,
            right: 50,
            bottom: 20,
            top: 50,
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.3)",
            borderWidth: 0, // 提示框浮层的边框宽。
            // padding: 5, // 提示框浮层内边距，
            textStyle: {
              // 提示框浮层的文本样式。
              color: "#fff",
              fontStyle: "normal",
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontSize: 12,
            },
          },
          xAxis: [
            {
              type: "category",
              // 横向网格
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              // x轴字体设置
              axisLabel: {
                show: true,
                textStyle: {
                  fontSize: 12,
                  color: "#fff",
                },
              },
              // data: this.xData,
            },
          ],
          yAxis: [
            {
              name: "kW",
              type: "value",

              splitLine: {
                show: false,
                lineStyle: {
                  type: "dotted",
                },
              },
              // y轴字体设置
              axisLabel: {
                show: true,
                textStyle: {
                  fontSize: 12,
                  color: "#fff",
                },
              },
            },
            {
              name: "%",
              type: "value",
              splitLine: {
                show: false,
              },
              // y轴字体设置
              axisLabel: {
                show: true,
                // formatter: '{value} %',
                textStyle: {
                  fontSize: 12,
                  color: "#fff",
                },
              },
            },
          ],
          series: [
            {
              name: this.title1,
              type: "line",
              smooth: false,
              // data: this.data1,
              symbol: "none", //拐点样式
              // symbolSize: 5, //拐点大小
              itemStyle: {
                color: "#6A5ACD",
                normal: {
                  lineStyle: {
                    // 系列级个性化折线样式
                    width: 1,
                    type: "solid",
                    color: "#00D3FF",
                  },
                },
              },
              encode: {
                // 将 "amount" 列映射到 X 轴。
                x: "historyTime",
                // 将 "product" 列映射到 Y 轴。
                y: "havePowerSystem",
              },
            },
            {
              name: this.title2,
              type: "line",
              smooth: false,
              // data: this.data2,
              symbol: "none", //拐点样式
              // symbolSize: 5, //拐点大小
              itemStyle: {
                color: "#6A5ACD",
                normal: {
                  lineStyle: {
                    // 系列级个性化折线样式
                    width: 1,
                    type: "solid",
                    color: "#00D19E",
                  },
                },
              },
              encode: {
                // 将 "amount" 列映射到 X 轴。
                x: "historyTime",
                // 将 "product" 列映射到 Y 轴。
                y: "havePowerNet",
              },
            },
            {
              name: this.title3,
              type: "line",
              smooth: true,
              // data: this.data3,
              symbol: "none", //拐点样式
              // symbolSize: 5, //拐点大小
              itemStyle: {
                color: "#6A5ACD",
                normal: {
                  lineStyle: {
                    // 系列级个性化折线样式
                    width: 1,
                    type: "solid",
                    color: "#ffc328", //线条渐变色
                  },
                },
              },
              encode: {
                // 将 "amount" 列映射到 X 轴。
                x: "historyTime",
                // 将 "product" 列映射到 Y 轴。
                y: "havePowerLoad",
              },
            },
            {
              name: this.title4,
              type: "line",
              smooth: true,
              symbol: "none", //拐点样式
              yAxisIndex: 1,
              itemStyle: {
                color: "#6A5ACD",
                normal: {
                  lineStyle: {
                    // 系列级个性化折线样式
                    width: 1,
                    type: "solid",
                    color: "#d04c79", //线条渐变色
                  },
                },
              },
              encode: {
                // 将 "amount" 列映射到 X 轴。
                x: "historyTime",
                // 将 "product" 列映射到 Y 轴。
                y: "soc",
              },
            },
          ],
        },
        true
      );
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.line-container {
    height: 100%;
    width: 100%;
}
.line-container /deep/ canvas{
  width 100% !important;
  height: 100% !important
}
</style>
