<template>
  <div>
    <div class="siteName">{{ siteName }}</div>
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane>
        <span slot="label"
          ><i class="iconfont icon-siteList"></i> 站点信息</span
        >
        <site-data :is-fresh="isFresh" :siteName="siteName"></site-data>
      </el-tab-pane>
      <el-tab-pane label="站点拓扑">
        <span slot="label"
          ><i class="iconfont icon-wangluotuopu"></i> 站点拓扑</span
        >
        <topology :siteName="siteName"></topology>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import siteData from "./siteData.vue";
import Topology from "./topology.vue";
export default {
  components: { siteData, Topology },
  data() {
    return {
      siteId: "",
      rateId: "",
      isFresh: false,
      siteName: "",
    };
  },
  mounted() {
    this.siteId = this.$route.query.id;
    this.rateId = this.$route.query.rateId;
    this.siteName = this.$route.query.siteName;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index, event);
      this.isFresh = tab.index == 0 ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.siteName {
  position: absolute;
  height: 44px;
  z-index: 2000;
  right: 0;
  line-height: 44px;
  margin-right: 20px;
}
</style>
