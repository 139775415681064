<template>
  <div class="line-container">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/line");
export default {
  name: "BLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    isFresh: {
      type: Boolean,
      default() {
        return false;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    data1: {
      type: Array,
      default() {
        return [];
      },
    },
    data2: {
      type: Array,
      default() {
        return [];
      },
    },
    data3: {
      type: Array,
      default() {
        return [];
      },
    },
    data4: {
      type: Array,
      default() {
        return [];
      },
    },
    title1: {
      type: String,
      default() {
        return "";
      },
    },
    title2: {
      type: String,
      default() {
        return "";
      },
    },
    title3: {
      type: String,
      default() {
        return "";
      },
    },
    title4: {
      type: String,
      default() {
        return "";
      },
    },
    yName1: {
      type: String,
      default() {
        return "";
      },
    },
    yName2: {
      type: String,
      default() {
        return "";
      },
    },
    yAxisIndex: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    isFresh() {
      this.drawWeatherLine();
    },
    xData() {
      this.drawWeatherLine();
    },
    data1() {
      this.drawWeatherLine();
    },
    data2() {
      this.drawWeatherLine();
    },
    data3() {
      this.drawWeatherLine();
    },
    data4() {
      this.drawWeatherLine();
    },
    title1() {
      this.drawWeatherLine();
    },
    title2() {
      this.drawWeatherLine();
    },
    title3() {
      this.drawWeatherLine();
    },
    title4() {
      this.drawWeatherLine();
    },
    yName1() {
      this.drawWeatherLine();
    },
    yName2() {
      this.drawWeatherLine();
    },
    yAxisIndex() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      console.log("xData", this.xData);
      let arr = this.xData.map(({ value }) => value);
      let max = Math.max(...arr);
      let min = parseInt(Math.min(...arr)) - parseInt(5);
      console.log(arr, max, min, "***********");
      // 绘制图表
      var option = {
        dataset: {
          dimensions: ["value", "key"],
          source: this.xData,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          // padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
        },
        xAxis: [
          {
            type: "category",
            // 横向网格
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            // x轴字体设置
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
            // data: this.xData,
          },
        ],
        yAxis: [
          {
            name: this.yName1,
            type: "value",
            min: min,
            // y轴字体设置
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
            splitLine: {
              //坐标轴在grid区域中的分隔线（网格中的横线）
              show: true,
              lineStyle: {
                color: ["#2E3950"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            symbol: "none",
            encode: {
              //列映射到 X 轴。
              x: "key",
              // 列映射到 Y 轴。
              y: "value",
            },
            itemStyle: {
              color: "#6A5ACD",
              normal: {
                lineStyle: {
                  width: 2,
                  type: "solid",
                  // color: "#facc14",
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      // color: "#0066ff",
                      color: "#01f7f8",
                    },
                    {
                      offset: 1,
                      // color: "#01f7f8",
                      color: "#0066ff",
                    },
                  ]), //线条渐变色
                },
              },
            },
            markPoint: {
              //图表标注
              data: [
                {
                  type: "max",
                  name: "最大值",
                  itemStyle: {
                    //自定义标注的颜色
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      {
                        offset: 0,
                        // color: "#0066ff",
                        color: "#01f7f8",
                      },
                      {
                        offset: 1,
                        // color: "#01f7f8",
                        color: "#0066ff",
                      },
                    ]), //线条渐变色
                  },
                },
                {
                  type: "min",
                  name: "最小值",
                  itemStyle: {
                    //自定义标注的颜色
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      {
                        offset: 0,
                        // color: "#0066ff",
                        color: "#01f7f8",
                      },
                      {
                        offset: 1,
                        // color: "#01f7f8",
                        color: "#0066ff",
                      },
                    ]), //线条渐变色
                  },
                },
              ],

              label: {
                position: "insideTop", //标注文字位置
                distance: 6, //距离图形元素的距离
                textStyle: {
                  color: "#fff",
                  fontSize: "12px",
                },
              },
              symbolSize: [40, 32],
              symbolOffset: ["26%", "-50%"], //图标偏移
              animation: true,
              // symbol: 'path://m 0,0 h 48 v 20 h -30 l -6,10 l -6,-10 h -6 z', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', path://m 0,0 h 48 v 20 h -30 l -6,10 l -6,-10 h -6 z,  path://m 0,0 h 48 v 20 h -34 l -6,10 l -6,-10 h -2 z
              // symbol:
              //   "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAAA4CAYAAADgmebbAAAABHNCSVQICAgIfAhkiAAABCVJREFUeF7tnEtsVFUYx8859zH30WlnihDT6VBKm+nUxJjIhrDB6JqNRFSI0UBYII9Ewag7N4aNkAjTEjU+YkKTJhp27oyJiSEujBuRaQWZlocR294LdLjv+zl3SAeoRSalYb5Jv1meOee7//v/nX/u2ZyPsyV+/sfFTZEKRRnk3oiFMTAps9Q8Glu+A5xFtsRkEUJ4Jea8bL5Z/mVxNX7vAJwY7PUVZafn8wlXVaaDWJ2xPbm6fAm08v8cyKRCUwVvrcG8vMRYIZZgzNg3eXVhTQPOjeO93YrSudvyxbjN0wTkMe+rrPA7OqXbr8zOVz/re3/aSh5fh5MkpsqUV6fCzOePWRM9bpEDfercHhPYaX5w4lodjlcafnfGk8YoMa3fK1nVTXdz5yVtf/kj7o8WNwVRnK8Ea35svTRSkDiQV+2tmsKm+Hyp8Fog6b9dc4wpsgaHA+vM6oaOyC9yb+Sp92aYeZpOZTjAJCr02E3ndPdlHowUj0x62S/wSCMliQOFlLWbO6PFD/90s8fIElwObNSswwQHF5OGGoKDFEwii+AQHMQOIJZGySE4iB1ALI2SQ3AQO4BYGiWH4CB2ALE0Sg7BQewAYmmUHIKD2AHE0ig5BAexA4ilUXIIDmIHEEuj5BAcxA4glkbJITiIHUAsjZJDcBA7gFgaJYfgIHYAsTRKDsFB7ABiaZQcgoPYAcTS6smh+zk4CQ1oc3voZhtCNo2bbd7I4K4ZMM7bvllBqHNVSlonWf06hyKv1m5T19oR5Ctuhm5TI9kK/frsVj2WL9X7ENweHXrH9jvGrVidR6Jv1cqo9SDorPUi2F7rQ3DsDpxThRyLpJ2XfOrg0epd0a/aeyFgX5tvnf+r0fvGPro+q3aae23Qxi1fu9Vqkavt+UliulRvx9//OJ/2f1Cxk/e/v2vUyaEej7NdXIg/HI9Nz0XarCNwgNLFLcWJ08GjQktFVdmTzPBR66zE+uRU1q04TyiC5yUZBiKXjyWJWah9H5yFwWpp8FlZyEUGvBcgBhCt77cmAG64ETt3JcycXa4x61PWcyrjw7X+ZsZya6zUuqTfGgfBIwaXIQrL5qELvy6uvSSclRKw0nWcUuEF4GL4um9+WwXVa7Z+skOf1JztDOBn4+DET82ua/W8toJTP7yMDOQ5U98IQvj+cpQtP8zAHtl62pD5Zp/7X6b3Xbz+sPmY/m87OAvmeaWhHQFI6lTQ9d2DDN2o3dwm4uBm6sDkGUymN6ulbeEkL+ifKDwTydI221XPzDGj8SFdo8znukTwogDxTerA7+eaNQPbvLaGk5gJn/QYXtz1OgM2fdHNnN2gW1ukmOVSUfgVP3Sh6e8SNjD/OUpjFNisJufU0PNRyPokwSr6/okfml2HeV7bJ+dec+F4IcffvttdFrPxzWj7F7l7EQD2ZrgfAAAAAElFTkSuQmCC",
              symbol:
                "path://M736 101.3H288c-123.2 0-224 100.8-224 224v149.3c0 123.2 100.8 224 224 224h37.3v224l256-224H736c123.2 0 224-100.8 224-224V325.3c0-123.2-100.8-224-224-224z",
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
        ],
      };
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.line-container {
    height: 100%;
    width: 100%;
}
</style>
