export const formatDate = (times) => {
  const date = new Date(times);
  const Y = date.getFullYear();
  const M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const Min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const S =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  const dateTime = Y + "-" + M + "-" + D + " " + " " + H + ":" + Min + ":" + S;
  return dateTime;
};

export const getDate = (times) => {
  const date = new Date(times);
  const Y = date.getFullYear();
  const M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const dateTime = Y + "-" + M + "-" + D;
  return dateTime;
};

export const getTime = (times) => {
  const date = new Date(times);
  const H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const Min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const S =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  const dateTime = H + ":" + Min + ":" + S;
  return dateTime;
};
