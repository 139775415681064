<template>
  <div>
    <!--头部-->
    <el-row class="topContainer">
      <div class="topTxt">
        <div class="btnGroup" style="width: 500px">
          <el-radio-group
            style="margin-bottom: 30px"
            v-model="activeBtn"
            @change="getData1"
          >
            <el-radio-button
              v-for="(item, index) in dateBtn"
              :key="index"
              :label="index"
              >{{ item }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <div class="txtLeft">
          <el-date-picker
            v-model="value2"
            type="month"
            v-if="activeBtn === 1"
            placeholder="选择月"
            value-format="yyyy-MM"
          >
          </el-date-picker>
          <el-date-picker
            v-model="value3"
            type="year"
            v-else-if="activeBtn === 2"
            placeholder="选择年"
            value-format="yyyy"
          >
          </el-date-picker>
          <el-button
            @click="getData()"
            style="margin-left: 10px"
            v-show="activeBtn > 0"
            >确定</el-button
          >
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "rightComp",
  data() {
    return {
      activeBtn: 0, // 活跃按钮
      dateBtn: ["近7天", "月统计", "年统计"], // 时间按钮
      // 日选择器
      defaultTime: this.formatDate(new Date()),
      // 年选择器
      yearPick: "null", // 年选择器的值
      yearPickShow: false, // 年选择器的显示隐藏
      dateParam: "day", // day：0 || month：1 || year：2
      dateFormat: "YYYY-MM-DD",
      monthFormat: "YYYY-MM",
      yearFormat: "YYYY",
      value2: "",
      value3: "",
    };
  },
  mounted() {
    this.loadDatas();
  },
  methods: {
    loadDatas() {
      let dateType, date;
      if (this.dateParam == "day") {
        date = this.defaultTime;
        dateType = 0;
      } else if (this.dateParam == "month") {
        date = this.defaultTime.substring(0, 7);
        dateType = 1;
      } else {
        date = this.yearPick;
        dateType = 2;
      }
      this.$emit("itemclick", date, dateType);
      this.$emit("timeSelected", date, dateType);
      this.$emit("time-selected", date, dateType);
      this.$emit("timeSelectedBtn", date, dateType);
      this.$emit("timeSelectedBtn1", date, dateType);
      this.$emit("timeSelectedBtn2", date, dateType);
    },
    getData1(tab) {
      // console.log(tab);
      if (tab == 0) {
        this.$emit("getChartData", this.activeBtn, 0);
      }
    },
    // 2. 日|月|年 事件切换
    handleDate(index) {
      if (index == "" || index == 0) {
        this.$emit("getRecentData");
        this.activeBtn = index;
        this.dateParam = "day";
        this.defaultTime = this.formatDate(new Date());
        this.loadDatas();
      } else if (index == 1) {
        this.activeBtn = index;
        this.dateParam = "month";
        this.defaultTime = this.formatDate(new Date());
        this.loadDatas();
      } else if (index == 2) {
        this.activeBtn = index;
        this.dateParam = "year";
        this.yearPick = this.formatDate(new Date()).substring(0, 4);
        this.loadDatas();
      }
    },

    // 3. 日与月事件
    onChange(date, dateString) {
      // console.log(date, dateString)
      this.defaultTime = dateString;
      this.$emit("timeSelected", dateString, this.activeBtn);
      this.$emit("itemclick", dateString, this.activeBtn);
      this.$emit("timeSelected", dateString, this.activeBtn);
      this.$emit("timeSelectedBtn", dateString, this.activeBtn);
      this.$emit("timeSelectedBtn1", dateString, this.activeBtn);
      this.$emit("timeSelectedBtn2", dateString, this.activeBtn);
    },

    // 4.1 弹出日历和关闭日历的回调（年）
    handleOpenChange(status) {
      this.yearPickShow = status;
    },
    // 4.2 得到年份选择器的值（年）
    handlePanelChange(value) {
      this.yearPick = value;
      this.yearPickShow = false;
      this.$emit("timeSelected", value, this.activeBtn);
      this.$emit("itemclick", value, this.activeBtn);
      this.$emit("timeSelectedBtn", value, this.activeBtn);
      this.$emit("timeSelectedBtn1", value, this.activeBtn);
      this.$emit("timeSelectedBtn2", value, this.activeBtn);
    },

    // 日期化
    formatDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let weekday = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (weekday < 10) {
        weekday = "0" + weekday;
      }
      return year + "-" + month + "-" + weekday;
    },
    getData() {
      console.log(this.activeBtn);
      let x;
      if (this.activeBtn == 1) {
        x = this.value2;
      } else if (this.activeBtn == 2) {
        x = this.value3;
      }
      this.$emit("getChartData", this.activeBtn, x);
    },
  },
};
</script>

<style scoped lang="scss">
.topContainer {
  margin: 0 10%;
  height: 30px;
  .topTitle {
    font-size: 20px;
    font-weight: bold;
  }

  .topTxt {
    display: flex;

    .txtLeft {
      width: 72%;
      z-index: 100;
      margin-left: 8%;

      .txtValue {
        width: auto;
        margin-right: 5%;
      }

      .dateTime {
        float: right;
        width: 100%;
      }
    }

    .btnGroup {
      //flex: 1;
      width: 500px;
    }
  }
}

.activeColor {
  color: #fff !important;
  //text-shadow: 0 -1px 0 rgb(0 0 0);
  background: #1890ff !important;
  border-color: #1890ff;
  //box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

/deep/ .el-input--prefix .el-input__inner {
  //height: 35px;
  border: 1px solid #409eff;
}
/deep/ .el-button {
  border: 1px solid #409eff;
  background: #333650;
  color: #409eff;
  //height: 35px;
  //line-height: 10px;
}
/deep/ .el-button + .el-button {
  margin-left: 0px;
}
/deep/ .el-radio-button__inner {
  background: #333650 !important;
  border: 1px solid #409eff;
  color: #fff;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #409eff !important;
  border-color: #409eff;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #409eff;
}
</style>
