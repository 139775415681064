<template>
  <div class="tabbar-Line">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
export default {
  name: "bar",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    yData1: {
      type: Array,
      default() {
        return [];
      },
    },
    yData2: {
      type: Array,
      default() {
        return [];
      },
    },
    yData3: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    unit: {
      type: String,
      default() {
        return "";
      },
    },
    yName1: {
      type: String,
      default() {
        return "";
      },
    },
    yName2: {
      type: String,
      default() {
        return "";
      },
    },
    yName3: {
      type: String,
      default() {
        return "";
      },
    },
    yMaxValue: {
      type: Number,
      default() {
        return 0;
      },
    },
    yMinValue: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    xData() {
      this.drawWeatherLine();
    },
    yData1() {
      this.drawWeatherLine();
    },
    yData2() {
      this.drawWeatherLine();
    },
    yData3() {
      this.drawWeatherLine();
    },
    yMaxValue() {
      this.drawWeatherLine();
    },
    yMinValue() {
      this.drawWeatherLine();
    },
    unit() {
      this.drawWeatherLine();
    },
    yName1() {
      this.drawWeatherLine();
    },
    yName2() {
      this.drawWeatherLine();
    },
    yName3() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // 绘制图表
      var option = {
        dataset: {
          dimensions: ["value", "key"],
          source: this.xData,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
        },
        grid: {
          containLabel: true,
        },
        legend: {
          orient: "horizontal",
          icon: "rect",
          // x: "center",
          // y: "bottom",
          // top: 300,
          textStyle: {
            color: "#fff",
          },
          data: [this.yName1, this.yName2, this.yName3],
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#2E3950", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
            axisLabel: {
              textStyle: {
                color: "#fff", //坐标值得具体的颜色
              },
              interval: 0,
            },
            axisTick: false,
            // data: this.xData,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.unit,
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#2E3950", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
            axisLabel: {
              textStyle: {
                color: "#e6e6e6", //坐标值得具体的颜色
              },
            },
            axisTick: false,
            splitLine: {
              //坐标轴在grid区域中的分隔线（网格中的横线）
              show: true,
              lineStyle: {
                color: ["#2E3950"],
                width: 1,
                type: "solid",
              },
            },
            splitArea: {
              //坐标轴在grid区域中的分隔区域（间隔显示网格区域）
              interval: 1, //显示间隔的规律
              // show: true,
              show: false,
              areaStyle: {
                //分隔区域的样式
                color: ["rgba(255,255,255,0.03)", "rgba(255,255,255,0)"],
              },
            },
          },
          {
            type: "value",
            name: "%",
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#2E3950", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
            axisLabel: {
              textStyle: {
                color: "#e6e6e6", //坐标值得具体的颜色
              },
            },
            axisTick: false,
            splitLine: {
              //坐标轴在grid区域中的分隔线（网格中的横线）
              show: true,
              lineStyle: {
                color: ["#2E3950"],
                width: 1,
                type: "solid",
              },
            },
            splitArea: {
              //坐标轴在grid区域中的分隔区域（间隔显示网格区域）
              interval: 1, //显示间隔的规律
              show: true,
              areaStyle: {
                //分隔区域的样式
                color: ["rgba(255,255,255,0.03)", "rgba(255,255,255,0)"],
              },
            },
          },
        ],
        series: [
          {
            name: this.yName1,
            type: "bar",
            barGap: 0.2,
            barWidth: 15,
            itemStyle: {
              normal: {
                show: true,
                // color: "#5dc7f0",
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00D6F8",
                  },
                  {
                    offset: 1,
                    color: "#2391FF",
                  },
                ]),
                barBorderRadius: 10,
              },
            },
            encode: {
              x: "key",
              y: "value",
            },
          },
        ],
      };
      if (this.xData.length > 12) {
        option.series[0].barWidth = 8;
        option.xAxis[0].axisLabel.interval = 5;
      }
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabbar-Line{
  width: 100%;
  height: 350px !important;
  }
</style>
