<template>
  <div class="site-detail-box flex">
    <div class="left">
      <div class="site-info-box">
        <div class="title">
          <lable-name name="站点信息"></lable-name>
        </div>
        <div class="site-info-top flex">
          <div class="site-info">
            <div class="state">
              <div class="title">站点状态</div>
              <div class="val flex items-middle">
                <div
                  class="point"
                  :class="
                    siteData.siteInfo.status == '运行'
                      ? 'bg-green'
                      : siteData.siteInfo.status == '故障'
                      ? 'bg-red'
                      : 'bg-yellow'
                  "
                ></div>
                <div class="value">{{ siteData.siteInfo.status }}</div>
              </div>
            </div>
            <div class="runDay">
              <count :countEnterNum="siteData.siteInfo.totalRunDay"></count>
            </div>
          </div>
          <div class="soc">
            <wapper
              :socData="siteData.siteInfo.soc"
              :state="siteData.siteInfo.status"
            ></wapper>
          </div>
        </div>
        <div class="site-info-bottom flex space-around">
          <div class="total-val" v-for="(v, i) in statisticalList" :key="i">
            <icon-title
              :imgSrc="v.src"
              :title="v.title"
              :value="v.value"
              :unit="v.unit"
            ></icon-title>
          </div>
        </div>
      </div>
      <div class="zhibao-box">
        <div class="zhibao-info flex space-between">
          <div class="zhibao-left">
            <div class="title" :class="zhiBaoData.sysRate > 0 ? '' : 'title1'">
              系统在保
            </div>
            <div class="val padding-sm-top">
              <span style="font-size: 20px; font-weight: 800">{{
                zhiBaoData.sysDay
              }}</span>
              <span style="font-size: 14px"> 天</span>
              <!-- <span style="font-size: 12px; color: #dc4c79">已过质保期</span> -->
            </div>
            <div
              class="date padding-lg-top"
              style="font-size: 14px; color: #dc4c79"
            >
              <div>{{ zhiBaoData.sysStartTime }}</div>
              <!-- <div>~</div> -->

              <div>
                {{ zhiBaoData.sysEndTime }}
              </div>
              <!-- {{ zhiBaoData.startTime }}~{{ zhiBaoData.endTime }} -->
            </div>
            <div class="title padding-lg-top">电池循环数</div>
            <div class="val padding-sm-top">
              <span style="font-size: 20px; font-weight: 800">{{
                siteData.cycleNumber
              }}</span>
              <span style="font-size: 14px">次</span>
            </div>
          </div>
          <div class="zhibao-left">
            <div class="title" :class="zhiBaoData.rate > 0 ? '' : 'title1'">
              电池在保
            </div>
            <div class="val padding-sm-top">
              <span style="font-size: 20px; font-weight: 800">{{
                zhiBaoData.day
              }}</span>
              <span style="font-size: 14px"> 天</span>
            </div>
            <div
              class="date padding-lg-top"
              style="font-size: 14px; color: #dc4c79"
            >
              <div>{{ zhiBaoData.startTime }}</div>
              <!-- <div>~</div> -->

              <div>
                {{ zhiBaoData.endTime }}
              </div>
            </div>
            <!-- <div class="title padding-lg-top">电池循环数</div>
            <div class="val padding-sm-top">
              <span style="font-size: 20px; font-weight: 800">{{
                siteData.cycleNumber
              }}</span>
              <span style="font-size: 14px">次</span>
            </div> -->
          </div>
          <div class="zhibao-right">
            <div class="chart">
              <ring-2
                :data1="zhiBaoData.sysRate"
                :data2="zhiBaoData.rate"
              ></ring-2>
              <!-- <ring-1
                :chartData="zhiBaoData.rate"
                :ringColor="ringColor"
              ></ring-1> -->
            </div>
          </div>
        </div>
      </div>
      <div class="use-box">
        <div class="title-box">
          <div class="title">
            <span>资产累计利用率</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="资产累计利用率=充电量/[（当前日-质保开始日）天数*环境利用比*日充电次数*装机容量*电池衰减]"
              placement="right"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div class="totalUtilization-value">
            {{ siteData.totalUtilization + "%" }}
          </div>
        </div>
        <div class="use-chart">
          <use-line
            :xData="siteData.listUtilization"
            :is-fresh="isFresh"
          ></use-line>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="title-box flex flex-start items-middle">
          <div class="title">
            <lable-name name="收益及电量信息"> </lable-name>
          </div>
          <div class="date-select">
            <date-select
              @getChartData="getChartData"
              @getRecentData="getRecentData"
            ></date-select>
          </div>
        </div>
        <div class="bottom-box flex">
          <div class="chart">
            <single-bar :xData="listEarnings" yName1="收益（元）"> </single-bar>
          </div>
          <div class="chart" style="margin-left: -70px">
            <double-bar
              :xData="listDisOrCharge"
              yName1="充电量（kWh）"
              yName2="放电量（kWh）"
            ></double-bar>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="title-box flex space-between items-middle">
          <div class="title">
            <lable-name name="功率分析"></lable-name>
          </div>
          <div class="power-time-form">
            <el-form :model="timeForm">
              <el-date-picker
                v-model="timeForm.time"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                @change="getPowerDate"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form>
          </div>
        </div>
        <div class="power-chart">
          <power-line
            :xData="powerData"
            title1="储能功率"
            title2="电网功率"
            title3="负载功率"
            title4="系统SOC"
            :is-fresh="isFresh"
          ></power-line>
        </div>
      </div>
      <div class="bottom flex">
        <div class="ark-table">
          <div class="title">
            <lable-name name="设备信息"></lable-name>
          </div>
          <div class="table-box">
            <table-auto-scroll
              :cols="siteData.arkInfo"
              :siteName="siteName"
            ></table-auto-scroll>
          </div>
        </div>
        <div class="income-process">
          <div class="title">
            <lable-name name="回本周期"></lable-name>
          </div>
          <!-- <div class="money-box">
            <el-form :model="moneyForm" inline>
              <el-form-item prop="money" label="投资额">
                <el-input v-model="moneyForm.money" clearable>
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="onSubmit">确定</el-button>
              </el-form-item>
            </el-form>
          </div> -->
          <div class="chart">
            <ring-1
              title="回本进度"
              :chartData="invest"
              chartClass="75"
              :is-fresh="isFresh"
            ></ring-1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LableName from "../../components/LableName.vue";
import {
  getSiteDetail,
  oneStatisticsInfo,
  oneSiteHomeInfo,
  sitePowerStatistics,
} from "@/api/siteList";
import { getDate } from "@/utils/formatDate.js";
import IconTitle from "../../components/iconTitle.vue";
import Ring1 from "./components/ring1.vue";
import DateSelect from "../../components/dateSelect.vue";
import DoubleBar from "../../components/doubleBar.vue";
import SingleBar from "../../components/singleBar.vue";
import UseLine from "./components/useLine.vue";
import Wapper from "./components/wapper.vue";
import Count from "./components/count.vue";
import PowerLine from "./components/powerLine.vue";
import TableAutoScroll from "./components/tableAutoScroll.vue";
import Ring2 from "./components/ring2.vue";

export default {
  components: {
    LableName,
    IconTitle,
    Ring1,
    DateSelect,
    DoubleBar,
    SingleBar,
    UseLine,
    Wapper,
    Count,
    PowerLine,
    TableAutoScroll,
    Ring2,
  },
  data() {
    return {
      chargeStatus: ["空闲", "充电", "放电"],
      timeType: ["", "尖峰", "高峰", "平期", "谷期", "高尖峰"],
      siteId: "",
      rateId: "",
      statisticalList: [
        // {
        //   title: "站点数量",
        //   value: 500,
        //   unit: "kwh",
        //   src: require("@/assets/imgs/u151.svg"),
        // },
        {
          title: "累计收益",
          value: 500,
          unit: "元",
          src: require("@/assets/imgs/u151.svg"),
        },
        {
          title: "本月收益",
          value: 500,
          unit: "元",
          src: require("@/assets/imgs/u166.svg"),
        },
        {
          title: "充电量",
          value: 500,
          unit: "kWh",
          src: require("@/assets/imgs/u176.svg"),
        },
        {
          title: "放电量",
          value: 500,
          unit: "kWh",
          src: require("@/assets/imgs/u171.svg"),
        },
        {
          title: "总装机容量",
          value: 500,
          unit: "kWh",
          src: require("@/assets/imgs/u161.svg"),
        },
        {
          title: "总装机功率",
          value: 500,
          unit: "kW",
          src: require("@/assets/imgs/u156.svg"),
        },
      ],
      totalData: 0,
      listDayDischarge: [],
      listDayCharge: [],
      listDayEff: [],
      chargeTime: [],
      earningTime: [],
      listDayEarnings: [],
      listEarnings: [],
      listDisOrCharge: [],
      rateObj: {},
      object: {
        pageNumber: 1,
        pageSize: 10,
      },
      btnAuth: {
        issued: "issued",
      },
      siteData: {
        siteInfo: {
          status: 0,
          totalRunDay: 0,
          soc: 0,
        },
        batteryCycles: 0,
        arkInfo: [],
        listUtilization: [],
        totalUtilization: 0,
        cycleNumber: 0,
      },
      timeForm: {
        time: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      powerData: [],
      zhiBaoData: {
        startTime: "",
        endTime: "",
        cycles: 0,
        day: 0,
        rate: 30,
        sysStartTime: "",
        sysEndTime: "",
        sysDay: 0,
        sysRate: 30,
      },
      moneyForm: {
        money: 0,
      },
      invest: 0,
      returnData: 0,
      ringColor: [
        {
          offset: 0,
          color: "#23e4ab", // 0% 处的颜色
        },
        {
          offset: 1,
          color: "#09aee9", // 100% 处的颜色
        },
      ],
      timer: null,
    };
  },
  props: {
    isFresh: {
      type: Boolean,
      default() {
        return false;
      },
    },
    siteName: {
      type: String,
      default() {
        return "";
      },
    },
  },
  // props: {
  //   siteId: {
  //     type: String,
  //     default: "",
  //   },
  //   rateId: {
  //     type: String,
  //     default: "",
  //   },
  // },
  mounted() {
    this.siteId = this.$route.query.id;
    this.rateId = this.$route.query.rateId;
    this.$nextTick(() => {
      this.initialize();
      this.getRecentData();
    });
    this.timer = setInterval(() => {
      setTimeout(() => {
        this.initialize();
      }, 0);
    }, 60000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
      this.timer = null;
    });
    // this.getRate();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    getRecentData() {
      oneStatisticsInfo({ dayNum: 7, siteId: this.siteId }).then((res) => {
        let listEarnings = res.data.data.listEarnings;
        listEarnings.forEach((v, i) => {
          v.key = v.key.substr(5, 5);
        });
        this.listEarnings = listEarnings;

        let listDisOrCharge = res.data.data.listDisOrCharge;
        listDisOrCharge.forEach((v) => {
          v.key = v.key.substr(5, 5);
        });
        this.listDisOrCharge = listDisOrCharge;
        // console.log(res.data.data, "-----------");
        // this.listEarnings = res.data.data.listEarnings;
        // this.listDisOrCharge = res.data.data.listDisOrCharge;
      });
    },
    initialize() {
      oneSiteHomeInfo({ siteId: this.siteId }).then((res) => {
        console.log(res.data.data);
        this.siteData.siteInfo = res.data.data.siteInfo;
        if (this.siteData.siteInfo.soc > 100 || this.siteData.soc < 0) {
          this.siteData.siteInfo.soc = 0;
        }
        // this.siteData.arkInfo = res.data.data.arkInfo;
        let arkInfo = res.data.data.arkInfo;

        arkInfo.sort((a, b) => {
          console.log(a.arkName.slice(7));
          return a.arkName.slice(7) - b.arkName.slice(7);
        });
        this.siteData.arkInfo = arkInfo;
        this.siteData.cycleNumber = res.data.data.cycleNumber;
        console.log(this.siteData.arkInfo, "arkInfo");
        this.siteData.listUtilization = res.data.data.listUtilization;
        this.siteData.totalUtilization = res.data.data.totalUtilization;
        if (res.data.data.accumulatedEarnings > 10000) {
          this.statisticalList[0].value =
            res.data.data.accumulatedEarnings / 10000;
          this.statisticalList[0].unit = "万元";
        } else {
          this.statisticalList[0].value = res.data.data.accumulatedEarnings;
        }
        if (res.data.data.curMonthEarnings > 10000) {
          this.statisticalList[1].value =
            res.data.data.curMonthEarnings / 10000;
          this.statisticalList[1].unit = "万元";
        } else {
          this.statisticalList[1].value = res.data.data.curMonthEarnings;
        }
        if (res.data.data.siteInfo.totalChargeNum > 1000) {
          this.statisticalList[2].value =
            res.data.data.siteInfo.totalChargeNum / 1000;
          this.statisticalList[2].unit = "MWh";
        } else {
          this.statisticalList[2].value = res.data.data.siteInfo.totalChargeNum;
        }
        if (res.data.data.siteInfo.totalDischargeNum > 1000) {
          this.statisticalList[3].value =
            res.data.data.siteInfo.totalDischargeNum / 1000;
          this.statisticalList[3].unit = "MWh";
        } else {
          this.statisticalList[3].value =
            res.data.data.siteInfo.totalDischargeNum;
        }
        this.totalData = res.data.data.accumulatedEarnings;
        // this.statisticalList[0].value = res.data.data.accumulatedEarnings;
        // this.statisticalList[1].value = res.data.data.curMonthEarnings;

        // this.statisticalList[2].value = res.data.data.siteInfo.totalChargeNum;
        // this.statisticalList[3].value =
        //   res.data.data.siteInfo.totalDischargeNum;
        this.statisticalList[4].value =
          res.data.data.siteInfo.installedCapacity;
        this.statisticalList[5].value = res.data.data.siteInfo.installedPower;
        this.invest = (
          (this.totalData / res.data.data.siteInfo.investment) *
          100
        ).toFixed(2);
        this.zhiBaoData.startTime =
          res.data.data.siteInfo.serviceBeginTime.substring(0, 10);
        this.zhiBaoData.endTime =
          res.data.data.siteInfo.serviceEndTime.substring(0, 10);
        var nowDate = new Date().getTime();
        let start = new Date(this.zhiBaoData.startTime).getTime();
        let end = new Date(this.zhiBaoData.endTime).getTime();
        this.zhiBaoData.day = Math.floor(
          Math.abs(nowDate - start) / (24 * 3600 * 1000)
        );
        let total = Math.floor(Math.abs(end - start) / (24 * 3600 * 1000));
        console.log(this.zhiBaoData.day, total, "*******");
        if (this.zhiBaoData.day > total) {
          this.zhiBaoData.rate = 0;
          this.zhiBaoData.day = -1;
        } else {
          this.zhiBaoData.rate = (
            ((total - this.zhiBaoData.day) / total) *
            100
          ).toFixed(2);
        }

        this.zhiBaoData.sysStartTime =
          res.data.data.siteInfo.sysServiceBeginTime.substring(0, 10);
        this.zhiBaoData.sysEndTime =
          res.data.data.siteInfo.sysServiceEndTime.substring(0, 10);
        var nowDate2 = new Date().getTime();
        let start2 = new Date(this.zhiBaoData.sysStartTime).getTime();
        let end2 = new Date(this.zhiBaoData.sysEndTime).getTime();
        this.zhiBaoData.sysDay = Math.floor(
          Math.abs(nowDate2 - start2) / (24 * 3600 * 1000)
        );
        let total2 = Math.floor(Math.abs(end2 - start2) / (24 * 3600 * 1000));
        console.log(this.zhiBaoData.sysDay, total2, "*******");
        if (this.zhiBaoData.sysDay > total2) {
          this.zhiBaoData.sysRate = 0;
          this.zhiBaoData.sysDay = "-";
        } else {
          this.zhiBaoData.sysRate = (
            ((total2 - this.zhiBaoData.sysDay) / total2) *
            100
          ).toFixed(2);
        }

        console.log(this.zhiBaoData);
        var totalCycle = 0;
        this.siteData.arkInfo.forEach((v) => {
          totalCycle += v.batteryCycles;
        });
        this.siteData.batteryCycles = parseInt(
          totalCycle / this.siteData.arkInfo.length
        );
      });
      this.getPower(getDate(new Date()));
    },
    getPowerDate(val) {
      console.log("getPowerDate", val);
      this.getPower(val);
    },
    getPower(date) {
      sitePowerStatistics({ siteId: this.siteId, datetime: date }).then(
        (res) => {
          // this.powerData = res.data.data;
          this.powerData = [];
          res.data.data.forEach((v, i) => {
            if (i % 10 == 0) {
              this.powerData.push(v);
            }
          });
        }
      );
    },
    setClass(key) {
      // console.log(key);
      let obj = this.statisticalList[key].class;
      return obj;
    },
    IssuedBtn() {
      this.$refs.commandIssued.open(this.siteId);
    },
    getChartData(activeBtn, value) {
      console.log(activeBtn, value, "******");
      if (activeBtn == 1) {
        oneStatisticsInfo({ monthNum: value, siteId: this.siteId }).then(
          (res) => {
            console.log(res.data.data, "-----------");
            let listEarnings = res.data.data.listEarnings;
            listEarnings.forEach((v, i) => {
              v.key = v.key.substr(8, 2);
            });
            this.listEarnings = listEarnings;

            let listDisOrCharge = res.data.data.listDisOrCharge;
            listDisOrCharge.forEach((v) => {
              v.key = v.key.substr(8, 2);
            });
            this.listDisOrCharge = listDisOrCharge;
          }
        );
      } else if (activeBtn == 2) {
        oneStatisticsInfo({ yearNum: value, siteId: this.siteId }).then(
          (res) => {
            // console.log(res.data.data, "-----------");
            // this.listEarnings = res.data.data.listEarnings;
            // this.listDisOrCharge = res.data.data.listDisOrCharge;
            let listEarnings = res.data.data.listEarnings;
            listEarnings.forEach((v, i) => {
              v.key = v.key.substr(5, 2);
            });
            this.listEarnings = listEarnings;

            let listDisOrCharge = res.data.data.listDisOrCharge;
            listDisOrCharge.forEach((v) => {
              v.key = v.key.substr(5, 2);
            });
            this.listDisOrCharge = listDisOrCharge;
          }
        );
      } else if (activeBtn == 0) {
        this.getRecentData();
      }
    },
    onSubmit() {
      this.returnData = ((this.totalData / this.moneyForm.money) * 100).toFixed(
        2
      );
      console.log(
        this.returnData,
        this.statisticalList[0].value,
        this.moneyForm.money
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.site-detail-box {
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: auto;
  overflow-y: scroll;
  background: #002140;
  .left {
    width: 28%;
    .site-info-box {
      background: #222e4a;
      margin: 10px;
      .site-info-top {
        padding: 10px 20px 0 20px;
        position: relative;
        .site-info {
          width: 50%;
          padding-left: 20px;
          .val {
            padding: 10px 0;
          }
        }
        .soc {
          width: 50%;
          height: 250px;
          position: absolute;
          left: 45%;
          top: -45px;
        }
      }
      .site-info-bottom {
        flex-wrap: wrap;
        padding: 0px 20px 10px 20px;
      }
    }
    .zhibao-box {
      background: #222e4a;
      margin: 10px;
      padding: 20px;
      .zhibao-info {
        .chart {
          width: 200px;
          height: 160px;
        }
      }
    }
    .use-box {
      margin: 10px;
      height: 410px;
      background: #222e4a;
      padding: 20px;
      .use-chart {
        width: 100%;
        height: 350px;
      }
    }
  }
  .right {
    width: 72%;
    .top {
      margin: 10px 10px 10px 0;
      .title-box {
        background: #222e4a;
        height: 50px;
        .date-select {
          width: 80%;
        }
      }
      .bottom-box {
        width: 100%;
        height: 300px;
        background: #222e4a;
        padding: 20px 0;
        .chart {
          //padding: 10px;
          width: 70%;
          margin-left: -40px;
          box-sizing: border-box;
          position: relative;
          margin-bottom: 20px;
        }
      }
    }
    .middle {
      margin: 10px 10px 10px 0;
      background: #222e4a;
      .power-time-form {
        padding: 10px 50px 0 0;
      }
      .power-chart {
        height: 300px;
        padding: 0 20px 20px 20px;
      }
    }
    .bottom {
      height: 347px;
      .ark-table {
        background: #222e4a;
        width: 50%;
        .title {
          padding: 10px;
        }
        .table-box {
          padding: 0 20px 20px 20px;
        }
      }
      .income-process {
        width: 48%;
        background: #222e4a;
        margin: 0 10px;
        box-sizing: border-box;
        .title {
          padding: 10px;
        }
        .money-box {
          padding: 10px 30px;
        }
        .chart {
          height: 200px;
          padding: 0 20px 20px 20px;
        }
      }
    }
  }
}
.title {
  color: #1ca8d9;
}
.title1 {
  color: red;
  text-decoration: line-through;
  border-color: red;
}
.point {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.bg-green {
  background-color: #00cc99;
}
.bg-red {
  background-color: #d9001b;
}
.bg-yellow {
  background-color: #ffc328;
}
.value {
  height: 30px;
  line-height: 30px;
}
.totalUtilization-value {
  height: 30px;
  line-height: 30px;
  font-size: 32px;
  padding-top: 20px;
  font-weight: 700;
}
.total-val {
  padding: 20px 0;
  width: 50%;
}
/deep/ .el-input--prefix .el-input__inner {
  border: 1px solid #409eff;
}
/deep/ .el-form-item__content .el-input-group {
  border: 1px solid #409eff;
  border-radius: 5px;
}
/deep/ .el-button {
  border: 1px solid #409eff !important;
  background: transparent !important;
  color: #fff;
}
</style>
